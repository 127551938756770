<template>
  <div v-if="Object.keys(discussion).length>0">
    <div class="chat-bubble">
      <div class="main-box-chat" v-show="showChatBox">
        <h4 class="font-weight-bold d-flex position-relative"
            style="gap:2px;align-items: center;border-bottom:1px solid #cdcdcd;padding:10px">
          <button v-if="activeBoxName!=boxNames.QUESTION_BOX" @click="back()">
            <v-icon color="#0071F9" dark>mdi-chevron-left
            </v-icon
            >
          </button>
          <!--          <StatusBadge v-if="activeBoxName==boxNames.QUESTION_BOX" :status="discussion.status"></StatusBadge>-->
          <StatusBadge v-if="activeBoxName!=boxNames.QUESTION_BOX" :status="activeQuestion.status"></StatusBadge>


          <span class="text-black" style="margin-left: 2px">
             {{ boxTitle }}
          </span>

          <button @click="closeChatBox()" class="position-absolute" style="top:8px;right:10px">
            <v-icon color="#0071F9" dark>mdi-close-circle
            </v-icon
            >
          </button>
        </h4>

        <!--SECTION DANH SÁCH CÁC TICKETS QUESTION-->
        <section class="list-tickets p-2" v-if="activeBoxName==boxNames.QUESTION_BOX">
          <div @click="openDetailConversationList(index)" class="mt-2 ticket-item cursor-pointer"
               v-for="(item,index) in discussion.questions" :key="index">
            <!--            <div class="font-weight-bold">{{ item }}</div>-->
            <div>
              <div style="margin-top:2px;display: flex;gap:2px;
              border-bottom:1px solid #cdcdcd;
              padding-bottom:10px;
              align-items: center;
              justify-content: space-between">
                <div class="font-weight-bold">
                  <span>{{ errorTypeLabel(item.type) }}</span>
                </div>
                <StatusBadge :status="item.status"></StatusBadge>
              </div>

              <p style="margin-top:10px">
                {{ item.question }}
              </p>

              <div class="d-flex" style="border-top:1px solid #cdcdcd;
              justify-content: space-between;
              align-items: center;
              gap:2px
">
                <div v-if="item.files.length">{{ item.files.length }} file đính kèm</div>
                <div>
                  Ngày gửi: {{ item.created_at | formatDate }}
                </div>
              </div>
            </div>
          </div>
        </section>

        <!--SECTION LIST TRAO DOI-->
        <section class="text-black" v-if="activeBoxName == boxNames.CONVERSATION_BOX">
          <div class="text-center" v-if="TICKET_STATUS.CLOSED(activeQuestion.status)">Cuộc hội thoại này đã được đóng từ
            <strong>
              {{ closedByLabel(activeQuestion.closed_by) }}
            </strong>
            <p
                v-if="activeQuestion.closed_reason"
                class="font-[600] text-gray-700"
            >
              Lý do:
              {{ activeQuestion.closed_reason }}
            </p>
          </div>
          <div class="conversation">
            <div class="message-item mt-2" v-for="(item,index) in messages" :key="index">
              <div class="font-weight-bold  text-black">
                <p v-if="item.type==SENDER_TYPE.TEACHER">Giáo viên</p>
                <p v-if="item.type==SENDER_TYPE.STUDENT && studentName">{{ studentName.name }}</p>
                <p v-if="item.type==SENDER_TYPE.STUDENT && !studentName">Học viên</p>
              </div>
              <hr>
              <p class="text-black">
                {{ item.message }}
              </p>
              <div v-if="item.files" style="gap:10px" class="d-flex justify-start align-start">
                <div style="flex-basis: 20%" v-for="(image,fileIndex) in item.files"
                     :key="fileIndex">
                  <img @click="previewImage(image)" :src="image" style="width:100%;height: 100%;cursor: pointer"/>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!TICKET_STATUS.CLOSED(activeQuestion.status)" class="d-flex justify-center"
               style="gap:10px;padding:10px">
            <v-btn v-if="allow_close_conversation" @click="closeConversation()"
                   style="flex-basis: 50%;color:#E20D2C;background: #FCE6E9">
              Đóng cuộc hội thoại
            </v-btn>

            <v-btn style="flex-basis: 50%;color: #0071F9;background: #E1EFFE"
                   @click="openReplyBox()">
              <v-icon color="#0071F9" dark>mdi-reply
              </v-icon
              >
              <span>
              Phản hồi
              </span>
            </v-btn>
          </div>
        </section>

        <!--SECTION REPLY-->
        <section v-if="activeBoxName==boxNames.REPLY_BOX" style="padding:12px">
          <p v-if="activeQuestion && activeQuestion?.question">Re:&nbsp;{{ activeQuestion.question }}</p>
          <form @submit.prevent="reply" style="position: relative;overflow: hidden">
            <v-textarea label="Nhập nội dung" v-model="teacherMessageInput"></v-textarea>
            <v-btn
                @click="reply"
                :loading="stateSendReply.loading"
                :disabled="!teacherMessageInput"
                style="flex-basis: 50%;color: #0071F9;background: #E1EFFE;float:right">
             <span>
              Gửi
             </span>
            </v-btn>
          </form>
        </section>

        <!--SECTION CLOSED CONVERSATION-->
        <section v-if="activeBoxName==boxNames.CONFIRM_CLOSE_CONVERSATION_BOX" style="padding:12px">
          <!--          <p>Bạn có chắc chắn muốn đóng cuộc hội thoại này không ?</p>-->
          <!--          <v-select
                        label="Chọn lý do đóng"
                        :items="['Học viên hỏi sai chủ đề','Khác']"
                        v-model="selectReasonClose"
                    ></v-select>-->
          <!--          {{ inputReasonClose }}-->
          <v-textarea label="Vui lòng nhập lý do"
                      v-model="inputReasonClose"></v-textarea>
          <v-btn
              :disabled="!inputReasonClose"
              @click="closeDiscussion()" style="width: 100%;color: #0071F9;background: #E1EFFE;">
          <span>
           Đóng cuộc hội thoại
        </span>
          </v-btn>
        </section>
      </div>


      <div class="btn-chat-bubble" @click="showChatBox=!showChatBox">
        <i class="fas fa-comments icon-menu fontawesome-icon-menu"></i>
        <div class="noti-count" v-if="notificationCount>0">
          {{ notificationCount }}
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import ApiService from "@/service/api.service";
import {SENDER_TYPE, TICKET_STATUS} from "@/view/pages/chat-with-students/Widget/Shared/STATUS";
import StatusBadge from "@/view/pages/chat-with-students/Widget/Shared/StatusBadge.vue";
import Swal from "sweetalert2";
import {errorTypeLabel} from './Shared/STATUS';

export default {
  name: "MainChatBox",
  components: {StatusBadge},
  data() {
    return {
      discussion: [],
      boxNames:
          {
            QUESTION_BOX: "QUESTION_LIST_BOX",
            CONVERSATION_BOX: "CONVERSATION_BOX",
            HIDE_BOX: "HIDE_BOX",
            REPLY_BOX: "REPLY_BOX",
            CONFIRM_CLOSE_CONVERSATION_BOX: "CONFIRM_CLOSE_CONVERSATION_BOX"
          },
      activeBoxName: "QUESTION_LIST_BOX",
      boxNameStack: [
        "QUESTION_LIST_BOX"
      ],
      discussionDetail: null,
      chooseQuestionIndex: 0,
      SENDER_TYPE,
      teacherMessageInput: "",
      stateSendReply: {
        loading: false,
        success: false,
        error: false
      },
      selectReasonClose: '',
      inputReasonClose: '',
      showChatBox: true,
      boxTitle: 'Danh sách câu hỏi',
    };
  },
  computed: {
    allow_close_conversation() {
      return this.activeQuestion?.last_message?.type == this.SENDER_TYPE.STUDENT || !this.activeQuestion?.last_message;
    },
    TICKET_STATUS() {
      return TICKET_STATUS
    },
    notificationCount() {
      return this?.discussion?.questions?.filter(question => {
        return question?.last_message?.type == this.SENDER_TYPE.STUDENT || !question?.last_message;
      })?.length ?? 0;
    },
    activeQuestion() {
      return this.discussion?.questions[this.chooseQuestionIndex]
    },
    messages() {
      const firstItem = {
        type: SENDER_TYPE.STUDENT,
        message: this.discussion?.questions[this.chooseQuestionIndex]?.question,
        files: this.discussion?.questions[this.chooseQuestionIndex]?.files ?? [],

        /*  files: [
            'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Hopetoun_falls.jpg/1200px-Hopetoun_falls.jpg',
            'https://assets.nst.com.my/images/articles/03xxworld_1596384698.jpg'
          ]*/
      }
      return [firstItem, ...this.discussion?.questions[this.chooseQuestionIndex]?.messages]
          ?? [firstItem]
    },
  },
  mounted() {
    this._getDetailDiscussion();
  },
  watch: {
    boxNameStack: {
      handler: function (newValue) {
        if (newValue.length >= 1) {
          this.activeBoxName = newValue[newValue.length - 1];
        }
      },
      deep: true
    }
  },
  methods: {
    closedByLabel(closedBy) {
      switch (closedBy) {
        case 1:
          return "Học viên";
        case 2:
          return "Giáo viên";
        case 3:
          return "Hệ thống";
      }
    },
    errorTypeLabel(errorType) {
      return errorTypeLabel(errorType);
    },
    async _getDetailDiscussion() {
      await ApiService.get(`prep-app/marking-ticket/discussion/${this.markingTicketId}`).then((response) => {
            if (response.status == 200) {
              this.discussion = response.data;
              if (Object.keys(this.discussion).length > 0) {
                if (this.$route.query.open_from && this.$route.query.open_from == "discussion_list") {
                  const questionId = this.$route.query.question_id;
                  this.chooseQuestionIndex = this.discussion.questions.findIndex(question => question.id == questionId) ?? 0;
                  this.openBox(this.boxNames.CONVERSATION_BOX);
                }
              }
            }
          }
      );
    },
    openBox(boxName) {
      this.boxNameStack.push(boxName);
    },
    openDetailConversationList(index) {
      this.openBox(this.boxNames.CONVERSATION_BOX);
      this.chooseQuestionIndex = index;
      this.boxTitle = this.errorTypeLabel(this.activeQuestion.type);
    },
    openReplyBox() {
      this.openBox(this.boxNames.REPLY_BOX);
    },
    back() {
      if (this.boxNameStack.length >= 1) {
        this.boxNameStack.pop();
        if (this.activeBoxName == this.boxNames.CONVERSATION_BOX) {
          this.boxTitle = 'Danh sách câu hỏi';
        }
      }
    },
    closeConversation() {
      this.openBox(this.boxNames.CONFIRM_CLOSE_CONVERSATION_BOX);
    },
    reply() {
      const questionId = this.discussion?.questions[this.chooseQuestionIndex]?.id;
      this.stateSendReply.loading = true;
      ApiService.post(`prep-app/marking-ticket/discussion/${this.markingTicketId}/question/${questionId}/message`,
          {
            message: this.teacherMessageInput
          }
      ).then(() => {
        this.teacherMessageInput = "";
        this.stateSendReply.success = true;
        Swal.fire(
            'Chúc mừng',
            'Phản hồi của bạn đã được gửi đi',
            'success'
        ).then(() => {
          this._getDetailDiscussion();
          this.back();
        })
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Có lỗi xảy ra',
          text: 'Có lỗi xảy ra, Vui lòng thử lại'
        })
        this.stateSendReply.error = true;
      }).finally(() => {
        this.stateSendReply.loading = false;
      });
    },
    closeDiscussion() {
      Swal.fire({
        title: 'Bạn có chắc chắn muốn đóng cuộc hội thoại này?',
        text: "Bạn sẽ không thể hoàn tác lại hành động này!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

        confirmButtonText: 'Đóng cuộc hội thoại'
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.put(`prep-app/marking-ticket/discussion/${this.markingTicketId}/question/${this.activeQuestion.id}/close`,
              {
                reason: this.inputReasonClose
              }
          ).then(() => {
            Swal.fire(
                'Đã đóng cuộc hội thoại',
                'Cuộc hội thoại đã được đóng',
                'success'
            ).then(() => {
              this._getDetailDiscussion();
              this.back();
            })
          }).catch((error) => {
            //console.log(error?.response?.data);
            //const message = error?.response?.data?.error?.message;
            const code = error?.response?.data?.error?.code;
            if (code == 3) {
              Swal.fire({
                icon: 'error',
                title: 'Có lỗi xảy ra',
                text: "Bạn không có quyền thực hiện thao tác này"
              });
            }

          })
        }
      });
    },
    closeChatBox() {
      this.showChatBox = false;
    },
    previewImage(src) {
      Swal.fire({
        imageUrl: src,
        imageWidth: 1200,
        customClass: 'swal-wide',
      })
    }
  },
  props: {
    markingTicketId: {
      type: Number,
      default: 0
    },
    studentName: {
      type: String,
      default: ''
    }
  }
};
</script>
<style>
.swal-wide {
  width: 850px !important;
}

/*.main-box-chat {
  background: white;
  box-shadow: 0px -4px 10px -1px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0px 0px;
  padding: 10px;
  width: 100%;
}*/

.main-box-chat .conversation {
  height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.main-box-chat .conversation .message-item {
  /* BG/White */

  background: #FFFFFF;
  /* Neutral/200 */

  border: 1px solid #EBEDF4;
  border-radius: 12px;
  padding: 12px;
}

.chat-bubble {
  background: #FFC107;
  box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: fixed;
  right: 20px;
  bottom: 130px;
}

.chat-bubble .btn-chat-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.chat-bubble i {
  color: black;
}

.chat-bubble .noti-count {
  background: #e20d2c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  left: -5px;
  font-size: 12px;
  font-weight: bold;
}

.chat-bubble .main-box-chat {
  -webkit-box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.1);
  background: white;
  width: 500px;
  position: absolute;
  right: 60px;
  bottom: 0px;
  overflow-y: auto;
}

.chat-bubble .list-tickets .ticket-item {
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.1);
}

.chat-bubble .list-tickets {
  color: black;
}

.p-2 {
  padding: 10px;
}

.text-black {
  color: black
}

.text-black * {
  color: black
}
</style>
