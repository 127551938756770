<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Marking Ticket Answer</h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <div class="mb-12">
        <v-row>
          <v-col cols="12">
            <template v-if="marking_ticket_detail.ticket_old">
              <div class="d-flex ml-7">
                <label
                    class="text-body-1 font-weight-bold fix-font"
                    style="font-size: 1.1rem !important"
                >Marked Ticket:
                </label>
                <div class="d-flex">
                  <template
                      v-for="(
                      ticket_old, index
                    ) in marking_ticket_detail.ticket_old"
                  >
                    <router-link
                        target="_blank"
                        class="text-body-1 text-justify ml-4 css-marked-ticket fix-font"
                        style="font-weight: 600"
                        :key="index"
                        :to="{
                        name: 'MarkingTicketAnswer',
                        params: { id: ticket_old.id },
                      }"
                    >
                      Ticket {{ index + 1 }},
                    </router-link>
                    <!-- <p class="text-body-1 text-justify ml-4 css-marked-ticket" style="font-weight: 600;" :key="index">Ticket {{index+1}},</p> -->
                  </template>
                </div>
              </div>
            </template>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive" style="overflow: unset">
              <table
                  class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                <tr class="text-left">
                  <th style="min-width: 250px" class="pl-7">
                      <span class="text-dark-75"
                      >Thông tin course, less/test</span
                      >
                  </th>
                  <th style="min-width: 120px">marker</th>
                  <th style="min-width: 100px">student</th>
                  <th style="min-width: 100px">status</th>
                  <th style="min-width: 100px">draft number</th>
                  <th style="min-width: 100px">question information</th>
                  <th style="min-width: 100px">Ticket Tag</th>
                  <template v-if="canAccess('destroy-ticket')">
                    <th style="min-width: 100px">Action</th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="pl-2 py-8">
                      <span
                          class="text-dark-75 d-block font-size-lg"
                          v-if="marking_ticket_detail.course"
                      >
                        <b>Course:</b> {{ marking_ticket_detail.course.name }}
                      </span>
                    <template v-if="marking_ticket_detail.test">
                      <router-link
                          :to="{
                            name: 'EditTest',
                            params: { id: marking_ticket_detail.test.id },
                          }"
                          target="_blank"
                      >
                          <span class="text-dark-75 d-block font-size-lg pt-1">
                            <b>Less/test:</b>
                            <span class="blue--text">{{
                                marking_ticket_detail.test.title
                              }}</span>
                          </span>
                      </router-link>
                    </template>
                    <span
                        class="text-dark-75 d-block font-size-lg pt-1"
                        v-if="marking_ticket_detail.skill_id"
                    >
                        <b>Skill:</b>
                        <template v-if="marking_ticket_detail.skill_id === 3">
                          Speaking
                        </template>
                        <template v-if="marking_ticket_detail.skill_id === 4">
                          Writing
                        </template>
                      </span>
                  </td>
                  <td>
                    <div
                        class="d-flex align-items-center"
                        v-if="marking_ticket_detail.student"
                    >
                      <div class="symbol symbol-50 symbol-light mr-4">
                          <span class="symbol-label">
                            <img
                                :src="
                                marking_ticket_detail.marker.avatar == null
                                  ? imgUserDefault
                                  : marking_ticket_detail.marker.avatar
                              "
                                class="h-75 align-self-end"
                                contain
                            />
                          </span>
                      </div>
                      <div>
                          <span
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ marking_ticket_detail.marker.name }}</span
                          >
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                        class="d-flex align-items-center"
                        v-if="marking_ticket_detail.student"
                    >
                      <div class="symbol symbol-50 symbol-light mr-4">
                          <span class="symbol-label">
                            <img
                                :src="
                                marking_ticket_detail.student.avatar == null
                                  ? imgUserDefault
                                  : marking_ticket_detail.student.avatar
                              "
                                class="h-75 align-self-end"
                                contain
                            />
                          </span>
                      </div>

                      <div style="line-height: 1.7">
                          <span class="text-dark-75 d-block font-size-lg"
                          ><b>Name:</b>
                            {{ marking_ticket_detail.student.name }}</span
                          >
                        <span class="text-dark-75 d-block font-size-lg"
                        ><b>Email:</b>
                            {{ marking_ticket_detail.student.email }}</span
                        >
                        <span class="text-dark-75 d-block font-size-lg"
                        ><b>Phone:</b>
                            {{ marking_ticket_detail.student.phone }}</span
                        >
                        <span
                            class="text-dark-75 d-block font-size-lg"
                            v-if="marking_ticket_detail.class"
                        ><b>Class:</b>
                            {{ marking_ticket_detail.class }}</span
                        >
                      </div>
                    </div>
                  </td>
                  <td>
                    <v-chip
                        class="ma-2 text-dark-75 font-weight-bolder white--text"
                        color="#F9A825"
                        v-if="marking_ticket_detail.status == 1"
                    >
                      Unmarked
                    </v-chip>
                    <v-chip
                        class="ma-2 text-dark-75 font-weight-bolder white--text"
                        color="#0277BD"
                        v-if="marking_ticket_detail.status == 2"
                    >
                      Marking
                    </v-chip>
                    <v-chip
                        class="ma-2 text-dark-75 font-weight-bolder white--text"
                        color="#2E7D32"
                        v-if="marking_ticket_detail.status == 3"
                    >
                      Marked
                    </v-chip>
                  </td>
                  <td>
                      <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="marking_ticket_detail.course"
                      >
                        {{ marking_ticket_detail.draft_number }}
                      </span>
                  </td>
                  <td>
                    <div v-if="marking_ticket_detail.question_info">
                      <div class="d-flex">
                          <span
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >total:</span
                          >
                        <span
                            class="text-subtitle-1 text-muted font-weight-bold ml-1"
                        >
                            {{
                            marking_ticket_detail.question_info.total_question
                          }}
                          </span>
                      </div>
                      <div class="d-flex">
                          <span
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >marked:</span
                          >
                        <span
                            class="text-subtitle-1 text-muted font-weight-bold ml-1"
                        >
                            {{
                            marking_ticket_detail.question_info
                                .total_question_marked
                          }}
                          </span>
                      </div>
                      <div class="d-flex">
                          <span
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >unmarked:</span
                          >
                        <span
                            class="text-subtitle-1 text-muted font-weight-bold ml-1"
                        >
                            {{
                            marking_ticket_detail.question_info
                                .total_question_unmarked
                          }}
                          </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="dropdown-tags d-flex flex-wrap">
                      <div
                          class="mx-1"
                          v-for="(tagsCurrent, indexTagsCurrent) in marking_ticket_detail.tags"
                          :key="`tag-current-${indexTagsCurrent}`"
                      >
                        <v-tooltip left top v-if="canAccess('destroy-ticket')">
                          <template v-slot:activator="{ on, attrs }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                                class="text-small d-inline-block text-truncate bg-success bg-opacity-10 rounded-xl py-1 px-3"
                                style="max-width: 150px;"
                            >
                              {{ tagsCurrent.name }}
                            </span>
                          </template>
                          <span>{{ tagsCurrent.name }}</span>
                        </v-tooltip>
                      </div>

                      <div class="position-relative">
                        <button
                            class="btn rounded-xl mx-1 py-1 px-3 btn-secondary d-flex align-items-center"
                            @click.stop="() => dropdownSlt = `dropdown-slt-0`"
                        >
                          Tags
                          <i class="text-small ml-2 fa fa-plus"/>
                        </button>

                        <div
                            class="position-absolute top-full right-0 bg-white zindex-2 shadow-lg rounded-xl"
                            :class="[
                              {'d-block': dropdownSlt == `dropdown-slt-0`},
                              {'d-none': dropdownSlt != `dropdown-slt-0`}
                          ]"
                        >
                          <p
                              class="pt-3 px-4 text-warning font-weight-bold"
                              v-if="tagsPerMultiSlt < tags.length"
                          >
                            chỉ chọn tối đa {{ tagsPerMultiSlt }} tag{{ tagsPerMultiSlt > 1 ? 's' : '' }}
                          </p>

                          <button
                              class="dropdown-item d-flex align-items-center"
                              :class="[{'bg-danger bg-opacity-10': marking_ticket_detail.tags.find(e => e.id == tag.id)}]"
                              v-for="(tag, indexTag) in tags"
                              :key="`tag-marking-${indexTag}`"
                              @click="() => {
                              updateTags({
                                indexTicket: 0,
                                markingId: marking_ticket_detail.id,
                                currentTags: marking_ticket_detail.tags,
                                newTag: marking_ticket_detail.tags.find(e => e.id == tag.id) ? undefined : tag.id,
                                removeTag: marking_ticket_detail.tags.find(e => e.id == tag.id) ? tag.id : undefined
                              });
                            }"
                          >
                            <p class="flex m-0">{{ tag.name }}</p>
                            <i v-if="marking_ticket_detail.tags.find(e => e.id == tag.id)"
                               class="ms-5 text-white fa fa-times"/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <template v-if="canAccess('destroy-ticket')">
                    <td>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <button
                              class="btn btn-icon btn-light-danger btn-sm ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteMarkingTicket"
                          >
                              <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color="">mdi-delete</v-icon>
                              </span>
                          </button>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>
          </v-col>

          <v-col cols="12">
            <!--            <div class="hr-answer-detail mb-4"></div>-->
            <!--            <template v-if="Object.keys(marking_ticket_detail).length != 0">-->
            <!--              <div class="d-flex ml-7">-->
            <!--                <label class="text-body-1 font-weight-bold" style="font-size: 1.1rem !important">Section: </label>-->
            <!--                <div class="ml-2">-->
            <!--                  <p class="text-body-1 text-justify">{{marking_ticket_detail.answers[0].question.section.title}}</p>-->
            <!--                  <ElementType :element="marking_ticket_detail.answers[0].question.section.element"></ElementType>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </template>-->
            <template v-if="!is_call_api">
              <QuestionDetail
                  v-for="(answer, i) in marking_ticket_detail.answers"
                  :key="i"
                  ref="questionDetails"
                  :answer="answer"
                  :indexAnswer="i"
                  :calculateMethod="marking_ticket_detail.calculateMethod"
                  class="ml-4 mt-10 mb-10"
              >
                <template v-slot:top>
                  <div class="hr-answer-detail"></div>
                </template>
              </QuestionDetail>
            </template>
            <div v-if="marking_ticket_detail.use_details == 1">
              <v-row>
                <v-col cols="12" class="pt-2">
                  <div class="d-flex">
                    <label
                        class="mt-4 text-body-1 font-weight-bold"
                        style="font-size: 1.1rem !important"
                    >Marking Criteria::
                    </label>
                    <div class="col-10 pl-5 pt-0">
                      <table class="table" style="border: 1px dashed #cdcdcd">
                        <thead class="thead-ipp">
                        <tr>
                          <th class="text-button fix-font" scope="col">
                            Criteria
                          </th>
                          <th class="text-button fix-font" scope="col">
                            Score & comment
                          </th>
                          <!--                          <th class="text-button fix-font" scope="col">Comment</th>-->
                        </tr>
                        </thead>
                        <tbody>
                        <template
                            v-for="(
                              detail, iDetail
                            ) in marking_ticket_detail.details"
                        >
                          <!--                          Phần nhập điểm và nhận xét criteria -->
                          <tr :key="iDetail">
                            <td class="fix-font" style="width: 330px">
                              <p class="text-h6 text-justify mb-5 p-3">
                                {{ detail.criteria_name }}
                              </p>
                            </td>
                            <td class="d-flex">
                              <v-text-field
                                  class="fix-font"
                                  type="number"
                                  :min="min_score"
                                  :max="max_score"
                                  style="width: 8%"
                                  maxlength="1"
                                  :value="detail.score"
                                  solo
                                  dense
                                  readonly
                                  @blur="checkMarkerTrackpoint('score')"
                                  @input="
                                    changeValueSroceDetail($event, iDetail)
                                  "
                              ></v-text-field>
                              <v-textarea
                                  solo
                                  auto-grow
                                  dense
                                  rows="1"
                                  class="ml-1 text-body-1 fix-font"
                                  style="width: 90%"
                                  :value="detail.comment"
                                  @blur="checkMarkerTrackpoint('remark')"
                                  @input="
                                    changeValueCommentDetail($event, iDetail)
                                  "
                              ></v-textarea>
                            </td>
                          </tr>

                          <!--                          Phần nhập dữ liệu sub-criteria-->
                          <tr :key="'item_sub-' + iDetail">
                            <td colspan="2">
                              <SelectSubCriteria
                                  @setDescriptorSubCriteria="
                                    setDescriptorSubCriteria
                                  "
                                  :iAnswer="0"
                                  :iDetail="iDetail"
                                  :sub_criteria_ticket="detail.sub_criteria"
                              />
                            </td>
                          </tr>
                        </template>

                        <!--                        Phần nhận xết tổng quát-->
                        <tr>
                          <td
                              class="text-body-1 text-right font-weight-medium fix-font"
                              style="
                                font-size: 1.1rem !important;
                                color: black;
                                padding-top: 1.2em;
                              "
                          >
                            Overall Score
                          </td>
                          <td style="max-width: 15px">
                            <v-text-field
                                type="number"
                                class="score-over overal-input fix-font"
                                solo
                                dense
                                @blur="checkMarkerTrackpoint('score')"
                                :value="marking_ticket_detail.score"
                                readonly
                            ></v-text-field>
                          </td>
                          <td></td>
                          <!--                          <td>-->
                          <!--                            <v-textarea class="comment-over text-body-1 fix-font" solo auto-grow dense rows="1"-->
                          <!--                                        v-model="marking_ticket_detail.comment"></v-textarea>-->
                          <!--                          </td>-->
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" class="pt-2">
                  <div class="d-flex">
                    <label
                        class="mt-4 text-body-1 font-weight-bold fix-font"
                        style="font-size: 1.1rem !important"
                    >Action Plan:
                    </label>
                    <div class="col-10 pl-5 pt-0">
                      <label class="font-weight-medium fix-font"
                      >General Comments</label
                      >
                      <v-textarea
                          class="comment-over text-body-1 fix-font"
                          solo
                          auto-grow
                          dense
                          rows="5"
                          @blur="checkMarkerTrackpoint('plan')"
                          v-model="general_comment"
                      ></v-textarea>

                      <div v-for="(item, index) in action_plans" :key="index">
                        <div
                            class="position-relative mt-2"
                            :class="`action-plan-item-${index}`"
                        >
                          <!--                          <input type="text"
                                                           style="width:100%;border:1px solid black;padding:5px;height:40px;border-radius: 5px"
                                                           class="comment-over text-body-1 fix-font" v-model="item.action"
                                                           />-->

                          <v-textarea
                              filled
                              auto-grow
                              :placeholder="`Action ${index + 1}`"
                              @blur="checkMarkerTrackpoint('plan')"
                              v-model="item.action"
                              class="mr-50"
                          ></v-textarea>

                          <button
                              class="position-absolute"
                              style="
                              top: 0px;
                              bottom: 29px;
                              right: 1px;
                              background: #fc0f0f;
                              color: white;
                              width: 50px;
                              border-bottom-right-radius: 5px;
                              border-top-right-radius: 5px;
                            "
                              @click="action_plans.splice(index, 1)"
                          >
                            Delete
                          </button>
                        </div>
                      </div>

                      <button
                          class="btn btn-warning mr-2 mt-2 mx-auto"
                          @click="addActionPlan()"
                      >
                        Add Action
                      </button>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12">
            <div style="text-align: center">
              <v-btn
                  class="btn font-weight-bold text-uppercase white--text"
                  large
                  color="light-blue"
                  @click="submitOrDraft('submit')"
              >Submit
              </v-btn>
              <v-btn
                  class="btn font-weight-bold text-uppercase white--text ml-2"
                  large
                  color="orange"
                  v-shortkey="['ctrl', 'shift', 's']"
                  @shortkey="submitOrDraft('draft')"
                  @click="submitOrDraft('draft')"
              >Save Draft
              </v-btn>
              <v-btn
                  style="display: none"
                  v-shortkey="['meta', 'shift', 's']"
                  @shortkey="submitOrDraft('draft')"
                  @click="submitOrDraft('draft')"
              >Save Draft
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <MainChatBox :marking-ticket-id="id" :student-name="marking_ticket_detail.student"></MainChatBox>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
import {mapState} from "vuex";
import "vue-loading-overlay/dist/vue-loading.css";
import marking_trackpoint from "./mixins/marking-trackpoint.js";
import SelectSubCriteria from "./tools/SelectSubCriteria";
import MainChatBox from "@/view/pages/chat-with-students/Widget/MainChatBox.vue";

export default {
  name: "MarkingTicketAnswer",
  mixins: [marking_trackpoint],
  components: {
    SelectSubCriteria,
    Loading: () => import("vue-loading-overlay"),
    QuestionDetail: () => import("./MarkingTicketQuestionDetail.vue"),
    MainChatBox,
    // ElementType: () => import('./DisplayElement/ElementType.vue'),
  },
  data() {
    return {
      is_call_api: false,
      marking_ticket_detail: {},
      countdown_save_draft: 180,
      timer_save_draft: null,
      action_plans: [{action: ""}, {action: ""}],
      general_comment: null,
      tags: [],
      tagsSlt: [],
      tagsPerMultiSlt: 1, // gía trị 1 để chỉ select dc 1 tag.
                          // hoặc tại hàm getTags set tagsPerMultiSlt = res.data.length sau khi get tags từ server.
                          // muốn select nhiều tags có thể set trực tiếp giá trị int lớn.
      dropdownSlt: null,
    };
  },
  async created() {
    this.is_call_api = true;
    await this.getMarkingTicketById();
    await this.getMarkingTagSpeaking();
    await this.getTags();
    // this.setAutoSaveDraft();
    this.is_call_api = false;
  },
  computed: {
    imgUserDefault() {
      return require("../../../../public/media/users/default.jpg");
    },
    id() {
      return this.$route.params.id;
    },
    ...mapState({
      dataMarkingTicketAnswer: (state) =>
          state.markingTicketAnswerStore.dataMarkingTicketAnswer,
      data_marking_tag_speaking: (state) =>
          state.markingTicketAnswerStore.data_marking_tag_speaking,
    }),
    min_score: function () {
      if (this.marking_ticket_detail.calculateMethod)
        return this.marking_ticket_detail.calculateMethod.min_score;
      return 1;
    },
    max_score: function () {
      if (this.marking_ticket_detail.calculateMethod)
        return this.marking_ticket_detail.calculateMethod.max_score;
      return 9;
    },
  },
  mounted() {
    this.$store.commit("markingTicketAnswerStore/resetDataMarkingTicketAnswer");
    this.$store.commit("markingTicketAnswerStore/resetTicketDetail");
    document.addEventListener('click', () => this.dropdownSlt = null);
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getMarkingTicketById() {
      let vm = this;
      this.is_call_api = true;
      await ApiService.get("prep-app/marking-ticket/" + this.id).then(function (
          res
      ) {
        if (res.status == 200) {
          vm.marking_ticket_detail = res.data;
          vm.marking_ticket_detail.question_info = vm.countQuestion(
              res.data.answers
          );
          vm.setDataActionPlan(res.data.action_plan);
          vm.$store.commit("markingTicketAnswerStore/setTicketDetail", {
            data: res.data,
          });
          vm.checkMarkerTrackpoint("open");
          vm.is_call_api = false;
        }
      });
    },
    countQuestion(answer) {
      let total_question_marked = 0;
      let total_question_unmarked = 0;
      for (let i = 0; i < answer.length; i++) {
        if (answer[i].score == null) {
          total_question_unmarked++;
        } else {
          total_question_marked++;
        }
      }
      return {
        total_question_marked: total_question_marked,
        total_question_unmarked: total_question_unmarked,
        total_question: total_question_unmarked + total_question_marked,
      };
    },
    changeValueSroceDetail(value, i) {
      this.caculateOverScore(value, i);
      this.marking_ticket_detail.details[i].score = value;
    },
    caculateOverScore(scoreDetail, indexScore) {
      let details = this.marking_ticket_detail.details;
      let totalScore = 0;
      let count = 0;
      for (let i = 0; i < details.length; i++) {
        details[i].score = details[i].score == null ? 0 : details[i].score;
        if (i == indexScore) {
          details[i].score = scoreDetail;
        }
        totalScore += parseInt(details[i].score);
        count++;
        if (isNaN(totalScore)) return;
      }
      let scoreOver = totalScore / count;
      if (
          this.marking_ticket_detail.calculateMethod
              .marking_criteria_calculate_method == 1
      ) {
        let rounddown = Math.floor(parseInt(totalScore) / 2) / 2;
        this.marking_ticket_detail.score = rounddown;
      } else {
        this.marking_ticket_detail.score = scoreOver;
      }
    },
    changeValueCommentDetail(value, i) {
      this.marking_ticket_detail.details[i].comment = value;
    },
    submitOrDraft(type) {
      let purpose = "submit";
      if (type === "draft" || type === "auto_draft") {
        purpose = "draft";
      }

      // console.log(this.$refs.questionDetails[0].getOutputDataWriting(purpose));
      let writingMarkingData = [];
      let requiredScriptData = [];
      if (this.marking_ticket_detail.use_details !== 1) {
        for (let i = 0; i < this.marking_ticket_detail.answers.length; i++) {
          let data =
              this.$refs.questionDetails[i].getOutputDataWriting(purpose);
          writingMarkingData.push(data);
        }
      } else {
        for (let i = 0; i < this.marking_ticket_detail.answers.length; i++) {
          if (this.marking_ticket_detail.answers[i].is_required_script === 1) {
            if (this.marking_ticket_detail.answers[i].question_type === 5) {
              let data =
                  this.$refs.questionDetails[i].getOutputDataRequiredScript();
              requiredScriptData.push(data);
            }
            if (
                this.marking_ticket_detail.answers[i].question_type === 9 &&
                this.marking_ticket_detail.answers[i].file != null
            ) {
              let data =
                  this.$refs.questionDetails[i].getOutputDataRequiredScript();
              requiredScriptData.push(data);
            }
          }
        }
      }

      console.log(this.dataMarkingTicketAnswer);
      console.log(writingMarkingData);

      let arrayConvert = this.dataMarkingTicketAnswer.map((e, index) => {
        const item = {
          id: e.id,
          score: e.score,
          comment: e.comment,
          marking_data: e.question_type === 9 ? e.marking_data : "",
          details: e.details,
          question_title: e.question.title,
          answer_script:
              e.is_required_script === 1 ? requiredScriptData[index] : "",
          is_required_script: e.is_required_script,
        };
        if (e.question_type == 5) {
          item.action_plan = {
            general_comment: e.general_comment,
            actions: e.action_plans,
          };
          item.marking_tags = writingMarkingData[index]?.marking_tags;
          const marking_data = {
            paragraph: writingMarkingData[index].paragraph,
            outputData: writingMarkingData[index].outputData,
            annotations: writingMarkingData[index].annotations,
          };
          item.marking_data = marking_data;
        }
        return item;
      });

      let data = {
        answers: arrayConvert,
      };
      if (this.marking_ticket_detail.use_details === 1) {
        data.details = this.marking_ticket_detail.details;
        data.score = this.marking_ticket_detail.score;
        data.comment = this.marking_ticket_detail.comment;
        data.action_plan = {
          general_comment: this.general_comment,
          actions: this.action_plans.map((e) => e.action),
        };
      }

      console.log(data);
      if (type === "submit") {
        let validateSubCri = this.validateSubmitSubCriteria(data);
        if (!validateSubCri) {
          return;
        }
        this.checkMarkerTrackpoint("submit");
        this.submit(data);
      } else if (type === "draft") {
        this.checkMarkerTrackpoint("save");
        this.saveDraft(data);
      }

      // else if (type === 'auto_draft') {
      //   this.saveDraft(data, true);
      // }
    },
    submit(data) {
      this.clearIntervalSaveDraft();
      let vm = this;
      // let validatorForm = this.validatorFormMarkingTicket(data);
      // if (!validatorForm) {
      //   return;
      // }
      this.is_call_api = true;
      ApiService.post("prep-app/marking-ticket/" + this.id, data)
          .then(function (res) {
            if (res.status === 202) {
              vm.is_call_api = false;
              Swal.fire({
                title: "",
                text: "Chấm bài thành công!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.$router.push({name: "MarkingTicket"});
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                  error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    validatorFormMarkingTicket(data) {
      let flat = true;
      if (this.marking_ticket_detail.use_details === 1) {
        for (let i = 0; i < data.details.length; i++) {
          if (data.details[i].score == null || data.details[i].score === "") {
            this.$toasted.error(
                "Hãy nhập điểm cho tiêu chí " +
                data.details[i].criteria_name +
                " !",
                {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                }
            );
            flat = false;
          }
        }
        for (let i = 0; i < data.answers.length; i++) {
          if (data.answers[i].marking_data == null) {
            this.$toasted.info(
                "Bạn chưa dữ liệu chấm cho câu hỏi " +
                data.answers[i].question_title +
                " !",
                {
                  theme: "bubble",
                  position: "top-right",
                  duration: 4000,
                }
            );
            //flat = false;
          }
        }
      } else {
        let answers = data.answers;
        for (let iAns = 0; iAns < answers.length; iAns++) {
          for (
              let iDetail = 0;
              iDetail < answers[iAns].details.length;
              iDetail++
          ) {
            if (answers[iAns].details[iDetail].score == null) {
              this.$toasted.error(
                  "Hãy nhập đủ các tiêu chí điểm cho câu hỏi: " +
                  answers[iAns].question_title +
                  " !",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  }
              );
              flat = false;
              return;
            }
          }
        }
      }
      return flat;
    },
    saveDraft(data) {
      let vm = this;
      // this.is_call_api = true;
      ApiService.post(
          "prep-app/marking-ticket/" + this.id + "/save-draft",
          data
      )
          .then(function (res) {
            if (res.status === 202) {
              // vm.is_call_api = false;
              vm.$toasted.success("Draft saved", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              // vm.$router.push({ name: 'MarkingTicket'})
              // vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                  error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
    },
    setAutoSaveDraft() {
      let vm = this;
      clearInterval(this.timer_save_draft);
      this.timer_save_draft = setInterval(() => {
        vm.submitOrDraft("auto_draft");
      }, vm.countdown_save_draft * 1000);
    },
    clearIntervalSaveDraft() {
      clearInterval(this.timer_save_draft);
      this.timer_save_draft = null;
    },
    async getMarkingTagSpeaking() {
      let vm = this;
      await ApiService.get(
          "prep-app/marking-tag/grouped-by-type?skill_id=3"
      ).then(function (res) {
        if (res.status === 200) {
          vm.$store.commit(
              "markingTicketAnswerStore/setdataMarkingTagSpeaking",
              {data: res.data}
          );
        }
      });
    },
    addActionPlan() {
      this.action_plans.push({action: ""});
    },
    setDataActionPlan(data = null) {
      if (data === null || data === undefined) {
        this.action_plans = [{action: ""}, {action: ""}];
        this.general_comment = null;
      } else {
        let action_plan = JSON.parse(data);
        this.action_plans = action_plan.actions.map((e) => {
          return {
            action: e,
          };
        });
        this.general_comment = action_plan.general_comment;
      }
    },
    changeDescriptorSubCriterial(value, indexSubCriterial, indexDetail) {
      let detail = this.marking_ticket_detail.details[indexDetail];
      // let scoreDetail = detail.score;
      let scoreByValDescriptor = detail.sub_criteria[
          indexSubCriterial
          ].descriptors.filter((e) => e.descriptor_id === value)[0].score;
      let commentByValDescriptor = detail.sub_criteria[
          indexSubCriterial
          ].descriptors.filter((e) => e.descriptor_id === value)[0].name;

      this.marking_ticket_detail.details[indexDetail].sub_criteria[
          indexSubCriterial
          ].score = scoreByValDescriptor;
      this.marking_ticket_detail.details[indexDetail].sub_criteria[
          indexSubCriterial
          ].comment = commentByValDescriptor;

      let data_score_sub_cateria = this.marking_ticket_detail.details[
          indexDetail
          ].sub_criteria
          .filter((sub_criteria) => {
            return (
                sub_criteria.score != null &&
                sub_criteria.score !== 0 &&
                sub_criteria.score !== ""
            );
          })
          .map((sub_criteria) => sub_criteria.score);

      if (data_score_sub_cateria.length > 0) {
        let min_score_sub_cateria = Math.min(...data_score_sub_cateria);
        this.marking_ticket_detail.details[indexDetail].score =
            min_score_sub_cateria;
        this.changeValueSroceDetail(min_score_sub_cateria, indexDetail);
      }

      // if (scoreDetail == null || scoreDetail > scoreByValDescriptor || scoreDetail == 0) {
      //   this.marking_ticket_detail.details[indexDetail].score = scoreByValDescriptor;
      //   this.changeValueSroceDetail(scoreByValDescriptor ,indexDetail);
      // }
    },
    setDescriptorSubCriteria(descriptor_id, iSub, iDetail) {
      this.marking_ticket_detail.details[iDetail].sub_criteria[
          iSub
          ].descriptor_id = descriptor_id;
      this.changeDescriptorSubCriterial(descriptor_id, iSub, iDetail);
    },
    validateSubmitSubCriteria(data) {
      let flat = true;

      if (data.details) {
        for (let iDetail = 0; iDetail < data.details.length; iDetail++) {
          for (
              let iSub = 0;
              iSub < data.details[iDetail].sub_criteria.length;
              iSub++
          ) {
            if (!data.details[iDetail].sub_criteria[iSub].descriptor_id) {
              this.toastedError(
                  "Hãy chọn đủ sub criteria: " +
                  data.details[iDetail].sub_criteria[iSub].name +
                  " - phần criteria: " +
                  data.details[iDetail].criteria_name
              );
              flat = false;
            }
          }
        }
      }
      for (let iAnswer = 0; iAnswer < data.answers.length; iAnswer++) {
        if (
            data.answers[iAnswer].details &&
            data.answers[iAnswer].details.length > 0
        ) {
          for (
              let iDetail = 0;
              iDetail < data.answers[iAnswer].details.length;
              iDetail++
          ) {
            for (
                let iSub = 0;
                iSub < data.answers[iAnswer].details[iDetail].sub_criteria.length;
                iSub++
            ) {
              if (
                  !data.answers[iAnswer].details[iDetail].sub_criteria[iSub]
                      .descriptor_id
              ) {
                this.toastedError(
                    "Hãy chọn đủ sub criteria: " +
                    data.answers[iAnswer].details[iDetail].sub_criteria[iSub]
                        .name +
                    " - criteria: " +
                    data.answers[iAnswer].details[iDetail].criteria_name +
                    " - Cho câu trả lời thứ " +
                    (iAnswer + 1)
                );
                flat = false;
              }
            }
          }
        }
      }

      return flat;
    },
    toastedError(mess = "") {
      this.$toasted.error(mess, {
        theme: "toasted-primary",
        position: "top-right",
        duration: 4000,
      });
    },
    deleteMarkingTicket() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete("prep-app/marking-ticket/" + this.id)
              .then(function (res) {
                if (res.status === 202) {
                  vm.$toasted.success("Xóa thành công !!", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  });
                  vm.$router.push({name: "MarkingTicket"});
                  vm.is_call_api = false;
                }
              })
              .catch(function (error) {
                console.log(error);
                vm.is_call_api = false;
              });
        }
      });
    },
    async updateTags({indexTicket, markingId, currentTags, newTag, removeTag}) {
      console.log(indexTicket, markingId, currentTags, newTag, removeTag);

      let tagsSlt = currentTags.map(e => e.id);

      if (newTag && this.tagsPerMultiSlt == 1) {
        tagsSlt = [newTag];
      }

      if (newTag && this.tagsPerMultiSlt > 1 && tagsSlt.length < this.tagsPerMultiSlt) {
        tagsSlt = [...tagsSlt, newTag];
      }

      if (removeTag) {
        tagsSlt = tagsSlt.filter(e => e != removeTag);
      }

      const urlUpdateTags = `/prep-app/marking-ticket/${markingId}/tags`;
      const that = this;
      await ApiService.put(
          urlUpdateTags,
          {tag_ids: tagsSlt}
      ).then(function () {
        that.marking_ticket_detail.tags = tagsSlt.map(e => that.tags.find((r)=>r.id == e) );
      });
    },
    async getTags() {
      const urlGetTags = '/prep-app/marking-ticket/tags';
      const that = this;
      await ApiService.get(urlGetTags).then(function (res) {
        if (res.status === 200) {
          that.tags = res.data;
          // dùng để set select dc nhiều tags cho ticket
          // that.tagsPerMultiSlt = res.data.length;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.fix-font {
  font-family: "Montserrat", sans-serif !important;
}

/* .table td {
  vertical-align: middle
} */
.hr-answer-detail {
  width: 100%;
  background-color: #f3f6f9;
  height: 5px;
}

.score-over.v-text-field--solo > .v-input__control > .v-input__slot {
  cursor: not-allowed !important;
  background: #80808040 !important;
}

.css-marked-ticket {
  color: black;
  cursor: pointer;
  text-decoration-line: underline;
}

.css-marked-ticket:hover {
  color: #369ffe;
}

.mr-50 {
  margin-right: 50px;
}
</style>
