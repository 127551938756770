<template>
  <div>
    <template v-for="(sub, iSub) in sub_criteria">
      <div
        :key="'sub_criteria-' + iAnswer + iDetail + iSub"
        style="width: 100%; border: 1px dashed rgb(205, 205, 205)"
        class="d-flex"
      >
        <div style="width: 15%; border-right: 1px solid rgb(205, 205, 205)">
          <p class="text-subtitle-1 text-justify font-weight-bold mb-0 p-3">
            {{ sub.code }}
          </p>
        </div>

        <div style="width: 85%" class="d-flex">
          <template v-for="(descriptor, iDescriptor) in sub.descriptors">
            <v-tooltip top :key="iDescriptor" color="#37474F">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  :id="'descriptor' + iAnswer + iDetail + iSub + iDescriptor"
                  @click="setActiveSubCriteria(descriptor, iDescriptor, iSub)"
                  class="text-subtitle-1 text-justify mb-0 p-3 d-flex align-center cursor-pointer border-subcriteria"
                  style="flex: 1"
                >
                  {{ descriptor.name }}
                </div>
              </template>
              <div>
                <p class="text-subtitle-1">{{ descriptor.comment }}</p>
              </div>
            </v-tooltip>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "SelectSubCriteria",
  props: {
    iDetail: {
      type: Number,
      default: 0,
    },
    iAnswer: {
      type: Number,
      default: 0,
    },
    sub_criteria_ticket: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sub_criteria: [],
    };
  },
  created() {
    this.recalculationDescriptors();
  },
  mounted() {
    this.autofitActiveDescriptorSubCri();
  },
  methods: {
    setActiveSubCriteria(descriptor, iDescriptor, iSub) {
      if (descriptor.descriptor_id == null) return;
      let descriptorSelector = document.getElementById(
        "descriptor" + this.iAnswer + this.iDetail + iSub + iDescriptor
      );
      if (descriptorSelector.classList.contains("active-subcritera")) {
        console.log("Không cho phép bỏ chọn");
        // descriptorSelector.classList.remove("active-subcritera")
      } else {
        descriptorSelector.parentElement
          .querySelectorAll(".text-subtitle-1")
          .forEach((e) => e.classList.remove("active-subcritera"));
        descriptorSelector.classList.add("active-subcritera");
        this.$emit(
          "setDescriptorSubCriteria",
          descriptor.descriptor_id,
          iSub,
          this.iDetail
        );
      }
    },
    autofitActiveDescriptorSubCri() {
      for (let iSub = 0; iSub < this.sub_criteria.length; iSub++) {
        if (this.sub_criteria[iSub].descriptor_id) {
          let iDescriptor = this.sub_criteria[iSub].descriptors.findIndex(
            (e) => e.descriptor_id === this.sub_criteria[iSub].descriptor_id
          );
          if (iDescriptor !== -1) {
            document
              .getElementById(
                "descriptor" + this.iAnswer + this.iDetail + iSub + iDescriptor
              )
              .classList.add("active-subcritera");
          }
        }
      }
    },
    recalculationDescriptors() {
      let sub_criteria = JSON.parse(JSON.stringify(this.sub_criteria_ticket));
      const max_number_descriptor = sub_criteria.reduce(function (
        sub_criteria_item_prev,
        sub_criteria_item_prev_current
      ) {
        return sub_criteria_item_prev.descriptors.length >
          sub_criteria_item_prev_current.descriptors.length
          ? sub_criteria_item_prev
          : sub_criteria_item_prev_current;
      }).descriptors.length;

      sub_criteria = sub_criteria.map(function (sub_criteria_item) {
        const ratio_short =
          max_number_descriptor - sub_criteria_item.descriptors.length;
        if (sub_criteria_item.descriptors.length < max_number_descriptor) {
          for (let i = 0; i < ratio_short; i++) {
            sub_criteria_item.descriptors.unshift({ descriptor_id: null });
          }
        }

        return sub_criteria_item;
      });

      // return sub_criteria;
      this.sub_criteria = sub_criteria;
    },
  },
};
</script>

<style scoped>
.border-subcriteria {
  border: 1px solid rgb(205, 205, 205);
}
.active-subcritera {
  border: 1px solid #6993ffbd;
  background-color: #00b0ff;
  color: white;
}
</style>
