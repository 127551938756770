// import ApiService from "@/service/api.service";

import { mapState } from "vuex";
import ApiService from "@/service/api.service";

export default {
  data() {
    return {
      list_action_trackpoint: [
        "open",
        "annotate",
        "score",
        "remark",
        "plan",
        "save",
        "submit",
        "play",
        "pause",
      ],
    };
  },
  computed: {
    ...mapState({
      ticket_detail: (state) => state.markingTicketAnswerStore.ticket_detail,
    }),
  },

  created() {},
  methods: {
    checkMarkerTrackpoint(action = "") {
      console.log(action);
      if (this.list_action_trackpoint.includes(action)) {
        this.apiAddMarkingTrackpoint(action);
      }
    },

    apiAddMarkingTrackpoint(action) {
      let data = {
        ticketID: this.ticket_detail.id,
        draft: this.ticket_detail.draft_number,
        markerID: this.ticket_detail.marker.id,
        action: action,
      };
      console.log(data);
      ApiService.post("prep-app/marker/add-marking-trackpoint", data).then(
        function (res) {
          if (res.status === 200) {
            console.log(res);
          }
        }
      );
    },
  },
};
